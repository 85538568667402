<template>
  <div v-if="data.status !== 'complete'">
    <h3 class="divider-hl primary darken-2 my-4">
      Kampagnen-Links
      {{ data.isApproved !== "1" ? " (Noch nicht aufrufbar)" : "" }}
    </h3>
    <v-banner two-line>
      <v-avatar slot="icon" color="blue" size="40">
        <v-icon icon="mdi-lock" color="white"> mdi-link </v-icon>
      </v-avatar>

      {{ this.$frontendUrl }}?uuid={{ data.uuid }}
    </v-banner>

    <v-banner two-line>
      <v-avatar slot="icon" color="blue" size="40">
        <v-icon icon="mdi-lock" color="white"> mdi-facebook </v-icon>
      </v-avatar>

      {{ this.$frontendUrl }}?uuid={{ data.uuid }}&adsrc=facebook
    </v-banner>

    <v-banner two-line>
      <v-avatar slot="icon" color="blue" size="40">
        <v-icon icon="mdi-lock" color="white"> mdi-google </v-icon>
      </v-avatar>

      {{ this.$frontendUrl }}?uuid={{ data.uuid }}&adsrc=google
    </v-banner>

    <v-banner v-if="data.isApproved !== '1'" class="red--text font-weight-bold">
      Da die Kampagne noch nicht freigegeben wurde, sind die Kampagnen noch
      gesperrt! Für eine Vorschau nutze bitte die Vorschau-Funktion der
      verknüpften Landingpage.
    </v-banner>
  </div>
</template>

<script>
export default {
  name: "CampaignLinks",
  props: ["data"],
};
</script>
