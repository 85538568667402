var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"pageTitle":_vm.pageTitle + '' + _vm.data.title,"pageDescription":_vm.pageDescription}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.fetchingData,"flat":""}},[_c('v-toolbar',{staticClass:"primary",attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Kampagne")]),_c('v-spacer'),(
              _vm.data.uuid &&
              _vm.data.isPageless !== '1' &&
              _vm.data.pages_id !== '' &&
              _vm.data.pages_id != null &&
              _vm.data.status !== 'complete'
            )?_c('v-btn',{staticClass:"mx-1 white--text",attrs:{"href":this.$frontendUrl + '?uuid=' + this.data.uuid,"target":"_blank","x-small":"","color":"blue","disabled":_vm.data.isApproved === '1' ? false : true,"fab":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-1 white--text",attrs:{"href":this.$adminUrl + 'campaigns/' + this.data.id,"target":"_blank","x-small":"","color":"green darken-3","disabled":_vm.data.isApproved === '1' ? false : true,"fab":""}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v("mdi-alpha-a")])],1)],1),(!_vm.fetchingData)?_c('CampaignSettings',{attrs:{"data":_vm.data}}):_vm._e()],1),(_vm.data.id)?_c('CampaignActions',{key:_vm.StateKey,attrs:{"data":_vm.data},on:{"stateChanged":function($event){return _vm.getData()}}}):_vm._e(),(_vm.data.facebookLeadAds === '1')?_c('AddFacebookLeadManually',{on:{"leadFetched":function($event){_vm.notesKey++;
          _vm.statisticKey++;}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.fetchingData)?_c('OrderSummary',{attrs:{"orderId":_vm.data.orders_id,"data":_vm.data,"ordersProducts_id":_vm.data.ordersProducts_id}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.fetchingData)?_c('Notes',{key:_vm.notesKey,attrs:{"route":`/orders/${_vm.data.orders_id}/products/${_vm.data.ordersProducts_id}/notes`}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(
              (!_vm.fetchingData && _vm.data.hasMitfitMember === '1') ||
              _vm.data.hasMitfitShop === '1'
            )?_c('QrCodeLinksMembers',{attrs:{"campaignUuid":_vm.data.uuid}}):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CampaignFunnelApp',{attrs:{"campaignId":this.$route.params.id,"companies_id":_vm.data.companies_id}})],1),(_vm.data.isApproved === '1')?_c('v-col',{attrs:{"cols":"12"}},[_c('CampaignStatistics',{attrs:{"data":_vm.data}})],1):_vm._e(),(!_vm.fetchingData)?_c('v-col',{attrs:{"cols":"12"}},[_c('CampaignEmbedCode',{attrs:{"uuid":_vm.data.uuid}})],1):_vm._e()],1),_c('v-dialog',{key:_vm.rerenderKey,attrs:{"max-width":"1600px","transition":"dialog-bottom-transition","scrollable":"","persistent":""},model:{value:(_vm.pageDialog),callback:function ($$v) {_vm.pageDialog=$$v},expression:"pageDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"red darken-3","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("(AKTIVE KAMPAGNE) Landingpage")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.pageDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[_c('AddedLandingpageDetail',{attrs:{"pageId":_vm.data.pages_id}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }