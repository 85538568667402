<template>
  <div>
    <PageHeader
      :pageTitle="pageTitle + '' + data.title"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mx-auto" :loading="fetchingData" flat>
          <v-toolbar dark class="primary" flat>
            <v-toolbar-title>Kampagne</v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="
                data.uuid &&
                data.isPageless !== '1' &&
                data.pages_id !== '' &&
                data.pages_id != null &&
                data.status !== 'complete'
              "
              :href="this.$frontendUrl + '?uuid=' + this.data.uuid"
              target="_blank"
              x-small
              color="blue"
              class="mx-1 white--text"
              :disabled="data.isApproved === '1' ? false : true"
              fab
              ><v-icon dark>mdi-eye</v-icon></v-btn
            >

            <!-- Link to admin -->
            <v-btn
              :href="this.$adminUrl + 'campaigns/' + this.data.id"
              target="_blank"
              x-small
              color="green darken-3"
              class="mx-1 white--text"
              :disabled="data.isApproved === '1' ? false : true"
              fab
              ><v-icon large dark>mdi-alpha-a</v-icon></v-btn
            >
          </v-toolbar>

          <CampaignSettings v-if="!fetchingData" :data="data" />
        </v-card>

        <CampaignActions
          v-if="data.id"
          :data="data"
          :key="StateKey"
          @stateChanged="getData()"
        ></CampaignActions>

        <AddFacebookLeadManually
          v-if="data.facebookLeadAds === '1'"
          @leadFetched="
            notesKey++;
            statisticKey++;
          "
        >
        </AddFacebookLeadManually>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <OrderSummary
              v-if="!fetchingData"
              :orderId="data.orders_id"
              :data="data"
              :ordersProducts_id="data.ordersProducts_id"
            ></OrderSummary>
          </v-col>

          <v-col cols="12">
            <!-- global component -->
            <Notes
              v-if="!fetchingData"
              :route="`/orders/${data.orders_id}/products/${data.ordersProducts_id}/notes`"
              :key="notesKey"
            />
          </v-col>

          <v-col cols="12">
            <!-- global component -->
            <QrCodeLinksMembers
              v-if="
                (!fetchingData && data.hasMitfitMember === '1') ||
                data.hasMitfitShop === '1'
              "
              :campaignUuid="data.uuid"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <CampaignFunnelApp
          :campaignId="this.$route.params.id"
          :companies_id="data.companies_id"
        />
      </v-col>

      <v-col cols="12" v-if="data.isApproved === '1'">
        <CampaignStatistics :data="data"></CampaignStatistics>
      </v-col>

      <v-col v-if="!fetchingData" cols="12">
        <CampaignEmbedCode :uuid="data.uuid"></CampaignEmbedCode>
      </v-col>
    </v-row>

    <v-dialog
      v-model="pageDialog"
      max-width="1600px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="rerenderKey"
    >
      <v-card>
        <v-toolbar dark color="red darken-3" elevation="0">
          <v-toolbar-title>(AKTIVE KAMPAGNE) Landingpage</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="pageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <AddedLandingpageDetail :pageId="data.pages_id" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Notes from "@/components/global/notes/Notes.vue";
import OrderSummary from "@/components/orders/OrderSummary.vue";
import CampaignActions from "@/components/campaign_management/CampaignActions.vue";
import AddedLandingpageDetail from "@/components/campaign_management/AddedLandingpageDetail.vue";
import CampaignStatistics from "@/components/campaign_management/CampaignStatistics.vue";
import QrCodeLinksMembers from "@/components/campaign_management/QrCodeLinksMembers.vue";
import AddFacebookLeadManually from "@/components/campaign_management/AddFacebookLeadManually.vue";
import CampaignFunnelApp from "@/components/campaign_management/CampaignFunnelApp.vue";
import CampaignEmbedCode from "@/components/campaign_management/CampaignEmbedCode.vue";
import CampaignSettings from "@/components/campaign_management/CampaignSettings.vue";
export default {
  name: "Campaigndetail",

  components: {
    PageHeader,
    Notes,
    OrderSummary,
    CampaignActions,
    AddedLandingpageDetail,
    CampaignStatistics,
    QrCodeLinksMembers,
    AddFacebookLeadManually,
    CampaignFunnelApp,
    CampaignEmbedCode,
    CampaignSettings,
  },

  data() {
    return {
      data: [],
      companyName: "",
      pageDialog: false,
      notesKey: 0,
      statisticKey: 0,
      fetchingData: false,
      fetchingMailChannels: false,
      fetchingExternalApis: false,
      fetchingCompanyEvents: false,
      patchRoute: "campaigns/" + this.$route.params.id,
      patchRouteChecked: "campaigns/" + this.$route.params.id + "/setChecked",
      patchRouteUnsetChecked:
        "campaigns/" + this.$route.params.id + "/unsetChecked",
      patchRoutePrioviderSetup:
        "campaigns/" + this.$route.params.id + "/setAdProviderSetup",
      patchRouteUnsetPrioviderSetup:
        "campaigns/" + this.$route.params.id + "/unsetAdProviderSetup",

      pageTitle: "Kampagne: ",
      pageDescription: "",
      adSources: [
        {
          name: "Other",
          value: "Other",
        },
        {
          name: "Facebook Ads",
          value: "Facebook Ads",
        },
      ],
      recommendationTypes: [
        {
          name: "WhatsApp",
          value: "whatsapp",
        },
      ],
      value: null,
      name: null,
      StateKey: 0,
      campaignSetup: false,
      rerenderKey: 0,
      fb_account_id: null,
      allMailChannels: [],
      allExternalApis: [],
      allEvents: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        "campaigns/" + this.$route.params.id
      );

      if (response.status === 200) {
        this.data = response.data.data;
        this.fb_account_id = response.data.data.facebookAccountId;
        this.fetchingData = false;
      }
    },
  },

  watch: {
    data: {
      handler() {
        this.StateKey += 1;
      },
      deep: true,
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
