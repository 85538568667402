<template>
  <v-chip
    v-if="data.status === 'open'"
    x-large
    color="red"
    text-color="white"
    class="campaign-status white--text ma-1"
  >
    <v-icon large left> mdi-close </v-icon>
    Nicht freigegeben
  </v-chip>

  <v-chip
    v-else-if="data.status === 'waiting'"
    x-large
    color="cyan"
    text-color="white"
    class="campaign-status ma-1"
  >
    <v-icon large left> mdi-clock-time-ten </v-icon>

    Wartet auf Start
  </v-chip>

  <v-chip
    v-else-if="data.status === 'active'"
    x-large
    color="green"
    text-color="white"
    class="campaign-status is-running white--text ma-1"
  >
    <v-icon large left> mdi-rocket </v-icon>
    Kampagne läuft
  </v-chip>

  <v-chip
    v-else-if="data.status === 'complete'"
    x-large
    color="blue-grey"
    class="campaign-status white--text ma-1"
  >
    <v-icon large left> mdi-checkbox-marked-circle-outline </v-icon>
    Abgeschlossen
  </v-chip>

  <v-chip
    v-else-if="data.status === 'paused'"
    x-large
    color="orange"
    class="campaign-status white--text ma-1"
  >
    <v-icon large left> mdi-power-sleep </v-icon>
    Pausiert
  </v-chip>
</template>

<script>
export default {
  name: "CampaignStatus",
  props: ["data"],
};
</script>

<style scoped>
.campaign-status {
  position: fixed;
  z-index: 999;
  top: 80px;
  right: 50px;
}

/* pumping animation of class is-running */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.is-running {
  animation: pulse 1s infinite;
}
</style>
